import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash.noop';
import { Link as RouterLink } from 'react-router-dom';

import { StyledButton } from './SimpleButton.styles';

const SimpleButton = ({ onClick, to, label, ...otherProps }) => {
  if (!label) {
    return null;
  }

  const toProps = to ? { component: RouterLink, to } : {};

  return (
    <StyledButton onClick={onClick} {...toProps} {...otherProps}>
      {label}
    </StyledButton>
  );
};

SimpleButton.displayName = 'SimpleButton';

SimpleButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  to: PropTypes.string
};

SimpleButton.defaultProps = {
  label: null,
  onClick: noop,
  to: null
};

export default SimpleButton;
