import styled, { css } from 'styled-components/macro';

export const StyledSearch = styled.div`
  ${({ theme: { mediaQueries, spacing, palette }, isDrawerOpen }) => css`
    @media ${mediaQueries.mdMax} {
      padding: ${isDrawerOpen ? 0 : `${spacing(1)}px 0`};
      input {
        font-size: 16px;
        line-height: initial;
      }
    }

    .MuiOutlinedInput-root {
      background: ${palette.common.white};
    }

    .MuiInputAdornment-positionEnd {
      cursor: pointer;
    }

    .MuiOutlinedInput-input {
      padding: ${spacing(2)}px;

      &.MuiOutlinedInput-inputAdornedStart {
        padding-left: 0;
      }
    }
  `};
`;

StyledSearch.displayName = 'StyledSearch';
