import { useEffect } from 'react';

import { useStore } from 'store';

const ErrorHandler = () => {
  const showErrorModal = useStore((state) => state.modalStore.showErrorModal);
  const error = useStore((state) => state.error);

  useEffect(() => {
    if (error) {
      showErrorModal();
    }
  }, [error]);

  return null;
};

export default ErrorHandler;
