export const COPY_PHONE_NUMBER_EVENT = 'COPY_PHONE_NUMBER_EVENT';
export const ADD_NOTE_TO_CALL_EVENT = 'ADD_NOTE_TO_CALL_EVENT';
export const SEND_SMS_EVENT = 'SEND_SMS_EVENT';
export const REFRESH_STUDENT_HISTORY = 'REFRESH_STUDENT_HISTORY';

export default {
  COPY_PHONE_NUMBER_EVENT,
  ADD_NOTE_TO_CALL_EVENT,
  SEND_SMS_EVENT,
  REFRESH_STUDENT_HISTORY
};
