import styled, { css } from 'styled-components/macro';
import React from 'react';

import { StyledVariable } from 'components/CustomSMSMessage/CustomSMSMessage.styles';
import Typography from 'components/Typography';

export const StyledHeading = styled((props) => (
  <Typography component="p" variant="body1" mb={2} {...props} />
))`
  font-weight: bold;
`;

StyledHeading.displayName = 'StyledHeading';

export const StyledFromName = styled((props) => (
  <Typography component="p" variant="body1" mb={0} {...props} />
))`
  ${({ theme: { palette, spacing } }) =>
    css`
      font-weight: bold;
      margin-bottom: ${spacing(0.5)}px;
      color: ${palette.grey[600]};
    `}
`;

StyledFromName.displayName = 'StyledFromName';

export const StyledPreview = styled.div`
  ${({ theme: { palette, spacing, shape } }) =>
    css`
      background: ${palette.backgrounds.hover};
      padding: ${spacing(2)}px ${spacing(2.5)}px;
      border-radius: ${shape.borderRadiusLarge}px;
      min-height: 50px;

      ${StyledVariable} {
        padding: 0;
        color: ${palette.text.primary};
        font-weight: bold;
      }
    `}
`;

StyledPreview.displayName = 'StyledPreview';
