import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PostsIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 20 20'}>
      <path d="M12 3.75C12 1.625 13.3333 1.25 13.3333 1.25V0H6.66667V1.25C6.66667 1.25 8 1.625 8 3.75H0V20H20V3.75H12ZM18.6667 18.75H1.33333V5H6.66667V6.25H9.33333V8.75H10.6667V6.25H13.3333V5H18.6667V18.75Z" />
    </SvgIcon>
  );
};

export { PostsIcon };
