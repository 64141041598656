import PropTypes from 'prop-types';
import React from 'react';

import Modals from 'app/constants/modals';
import { AppError, SMSPreview, SMSSendSuccess } from 'components/ModalContent';
import StyledModal from './Modal.styles';
import { useStore } from 'store';

const modalContentMap = (onClose) => ({
  [Modals.APP_ERROR]: <AppError onClose={onClose} />,
  [Modals.SMS_PREVIEW_MODAL]: <SMSPreview onClose={onClose} />,
  [Modals.SMS_SEND_SUCCESS_MODAL]: <SMSSendSuccess onClose={onClose} />
});

const Modal = ({ fullWidth, maxWidth }) => {
  const { activeModalType, hideModal } = useStore((state) => state.modalStore);

  const isModalOpen = Boolean(activeModalType);

  return (
    <StyledModal
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isModalOpen}
      onClose={hideModal}
      data-testid="modal"
    >
      {modalContentMap(hideModal)[activeModalType]}
    </StyledModal>
  );
};

Modal.defaultProps = {
  fullWidth: true,
  maxWidth: 'sm'
};

Modal.propTypes = {
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string
};

export default Modal;
