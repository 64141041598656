import styled, { css } from 'styled-components/macro';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { lighten } from 'polished';
import { spacing as materialSpacing } from '@material-ui/system';

export const StyledChip = styled(({ onDelete, onClick, ...props }) => (
  <Chip {...props} onDelete={onDelete} onClick={onClick} deleteIcon={<CloseIcon />} />
))`
  ${({ theme: { palette, spacing, shape }, onDelete, onClick }) =>
    css`
      background: ${palette.backgrounds.checkbox};
      color: ${palette.text.info};
      text-decoration: none;
      margin-right: ${spacing(1)}px;
      padding: 0;
      height: 24px;
      border-radius: ${shape.borderRadius / 2}px;

      :hover {
        background: ${onDelete || onClick
          ? lighten(0.05, palette.backgrounds.checkbox)
          : palette.backgrounds.checkbox};
        cursor: ${onDelete || onClick ? 'pointer' : 'default'};
      }

      .MuiChip-deleteIcon {
        height: 16px;
        margin: 0 0 0 -6px;
        color: ${palette.text.info};
        :hover {
          opacity: 0.75;
        }
      }

      .MuiChip-label {
        font-weight: bold;
        padding: 0 ${spacing(1)}px;
        margin-bottom: 1px;
      }
    `}

  ${materialSpacing}
`;

StyledChip.displayName = 'StyledChip';
