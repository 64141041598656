import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const getTimeFromNow = (timeStamp) => {
  return dayjs(timeStamp).fromNow();
};

export const displayTimeStamp = (timeStamp) => {
  const isTimestampYesterday = dayjs(timeStamp).isYesterday();
  const isTimestampToday = dayjs(timeStamp).isToday();

  if (isTimestampYesterday) {
    return `Yesterday ${dayjs(timeStamp).format('h:mm A')}`;
  }

  if (isTimestampToday) {
    return `Today (${getTimeFromNow(timeStamp)})`;
  }

  return `${dayjs(timeStamp).format('ddd D MMM YYYY')} (${getTimeFromNow(timeStamp)})`;
};

export const calculateDaysBetweenDates = (date) => {
  if (date) {
    const oneDay = 24 * 60 * 60 * 1000;
    const originalDate = new Date(date);
    const nowDate = new Date();

    return Math.round(Math.abs((nowDate - originalDate) / oneDay));
  }
  return null;
};

export const isAfterExportTime = () => {
  const day = dayjs().day();
  const hour = dayjs().hour();

  // If it is after Wednesday or after 10am on a Wednesday
  return day > 3 || (day === 3 && hour >= 10);
};
