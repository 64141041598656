import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import lodashGet from 'lodash.get';

import { Routes } from 'app/routes';
import { StyledSMSSendSuccess } from './ModalContentSMSSendSuccess.styles';
import Typography from 'components/Typography';
import { Icon, ICON_TYPES } from 'components/Icon';
import { useStore } from 'store';

const ModalContentSMSSendSuccess = ({ onClose }) => {
  const smsQueuedNames = useStore((state) => state.smsStore.getSmsQueuedNames());
  const smsQueuedLength = smsQueuedNames.length;

  const { state } = useLocation();
  const referrer = lodashGet(state, 'referrer');

  return (
    <StyledSMSSendSuccess>
      <DialogTitle disableTypography>
        <Icon name={ICON_TYPES.SMS} fontSize="large" />
        <Typography variant="h4" component="p">
          <span data-ref="modal-title">
            {smsQueuedLength > 0 ? smsQueuedLength : 'No'} message
            {smsQueuedLength !== 1 ? 's' : ''} requested
          </span>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {smsQueuedLength > 0 && (
          <Typography mb={2}>
            {`An SMS has been scheduled to be sent to the following students. Sometimes messages don't
            get sent right away so keep an eye on the message status before you call.`}
          </Typography>
        )}

        {smsQueuedNames && (
          <List dense={true} data-ref="student-names-list" disablePadding>
            {smsQueuedNames.map((name, index) => (
              <ListItem key={index} disableGutters data-ref="student-name">
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          data-ref="ok-button"
          component={RouterLink}
          to={referrer || Routes.Home}
          onClick={onClose}
        >
          OK, got it
        </Button>
      </DialogActions>
    </StyledSMSSendSuccess>
  );
};

ModalContentSMSSendSuccess.defaultProps = {
  onClose: () => {}
};

ModalContentSMSSendSuccess.propTypes = {
  onClose: PropTypes.func
};

export default ModalContentSMSSendSuccess;
