import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';
import lodashGet from 'lodash.get';

import { Icon, ICON_TYPES } from 'components/Icon';
import Chip from 'components/Chip';
import Grid from 'components/Grid';
import LoadingSpinner from 'components/LoadingSpinner';
import SMSPreview from 'components/SMSPreview';
import { StyledSMSPreview } from './ModalContentSMSPreview.styles';
import Typography from 'components/Typography';
import { useStore } from 'store';

const ModalContentSMSPreview = ({ onClose }) => {
  const sendSms = useStore((state) => state.smsStore.sendSms);
  const showSmsSuccessModal = useStore((state) => state.modalStore.showSmsSuccessModal);
  const { isFetchingSms, timeframe, template, getStudentsToSms } = useStore(
    (state) => state.smsStore
  );
  const selectedForSms = getStudentsToSms();

  const firstName = lodashGet(selectedForSms, '[0].firstName');

  return (
    <StyledSMSPreview>
      {isFetchingSms && <LoadingSpinner dark />}
      <DialogContent>
        <Grid container alignItems="center" mb={2}>
          <Icon name={ICON_TYPES.CONTACTS} fontSize="large" />
          <Typography variant="h4" component="p">
            <span data-ref="modal-title">
              {selectedForSms && selectedForSms.length > 0 ? selectedForSms.length : 'No'} recipient
              {selectedForSms && selectedForSms.length !== 1 ? 's' : ''}
            </span>
          </Typography>
        </Grid>
        {selectedForSms &&
          selectedForSms.map((student, index) => (
            <Chip key={index} label={student.fullName} mb={1} />
          ))}
        <Grid container alignItems="center" my={2}>
          <Icon name={ICON_TYPES.SMS} fontSize="large" className="sms-icon" />
          <Typography variant="h4" component="p">
            Your message
          </Typography>
        </Grid>
        <SMSPreview timeframe={timeframe} template={template} firstName={firstName} />
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          onClick={async () => {
            await sendSms();
            showSmsSuccessModal();
          }}
          disabled={!selectedForSms || selectedForSms.length === 0}
        >
          Confirm &amp; send
        </Button>
        <Button
          disableElevation
          size={'large'}
          variant="outlined"
          onClick={onClose}
          data-ref="cancel-button"
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledSMSPreview>
  );
};

ModalContentSMSPreview.defaultProps = {
  onClose: () => {}
};

ModalContentSMSPreview.propTypes = {
  onClose: PropTypes.func
};

export default ModalContentSMSPreview;
