import parsePhoneNumber from 'libphonenumber-js';

export const isValidMobileNumber = (number) => {
  const phoneNumber = number && parsePhoneNumber(number, 'AU');

  if (
    phoneNumber &&
    phoneNumber.isValid() &&
    phoneNumber.country === 'AU' &&
    phoneNumber.getType() === 'MOBILE'
  ) {
    return true;
  }

  return false;
};

export const isValidPhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumber(number, 'AU');

  if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'AU') {
    return true;
  }

  return false;
};

export const getFormattedPhoneNumber = (number) => {
  const phoneNumber = number && parsePhoneNumber(number, 'AU');

  if (phoneNumber) {
    return phoneNumber.number;
  }

  return null;
};
