export const SMS_WORKFLOW_PHONE_CALL = 'phone-call';
export const SMS_WORKFLOW_SMS_ONLY = 'sms-only';

export const workflowWithCallingEnabled = {
  [SMS_WORKFLOW_PHONE_CALL]: true,
  [SMS_WORKFLOW_SMS_ONLY]: false
};

export default {
  SMS_WORKFLOW_PHONE_CALL,
  SMS_WORKFLOW_SMS_ONLY
};
