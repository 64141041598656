import Modals from 'app/constants/modals';

const createModalStore = (set) => ({
  activeModalType: null,

  showErrorModal() {
    set((state) => {
      state.modalStore.activeModalType = Modals.APP_ERROR;
    });
  },
  showSmsPreviewModal() {
    set((state) => {
      state.modalStore.activeModalType = Modals.SMS_PREVIEW_MODAL;
    });
  },
  showSmsSuccessModal() {
    set((state) => {
      state.modalStore.activeModalType = Modals.SMS_SEND_SUCCESS_MODAL;
    });
  },
  hideModal() {
    set((state) => {
      state.modalStore.activeModalType = null;
    });
  }
});

export { createModalStore };
