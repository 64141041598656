import styled, { css } from 'styled-components/macro';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default styled((props) => (
  <Tooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
  ${({ theme: { palette } }) => css`
    pointer-events: auto;

    &.info-icon {
      fill: ${palette.grey['600']};
    }

    & .tooltip {
      margin: 0;
      color: ${palette.text.primary};
      padding: 0;
      background: none;
    }
  `};
`;

export const TooltipContent = styled('div')`
  ${({ theme: { palette, spacing, typography, shape }, width }) => css`
    padding: ${spacing(2)}px;
    color: ${palette.text.primary};
    border: solid 1px ${palette.info.main};
    border-radius: ${shape.borderRadius}px;
    background: ${palette.backgrounds.info};
    font-size: ${typography.body1.fontSize}px;
    max-width: ${width ? 'none' : '200px'};
    width: ${width ? `${width}px` : 'auto'};
    pointer-events: auto;
    cursor: default;
    line-height: 1.5;

    p {
      margin-top: 0;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;

      li {
        margin-bottom: ${spacing(1)}px;

        :last-child {
          margin-bottom: 0;
        }
      }
    }
  `};
`;
