import styled, { css } from 'styled-components/macro';

export const StyledSortBy = styled.div`
  ${({ theme: { palette, spacing, mediaQueries } }) =>
    css`
      display: flex;
      flex-direction: row;
      align-items: center;

      @media ${mediaQueries.md} {
        flex-direction: column;
        align-items: flex-start;
      }

      .MuiButtonBase-root {
        text-transform: none;
        font-weight: bold;
        border: none;
        white-space: nowrap;
        min-width: 0;
        padding: ${spacing(1.75)}px ${spacing(1.4)}px;

        @media ${mediaQueries.md} {
          padding: ${spacing(2)}px ${spacing(3)}px;
        }

        :not(.Mui-disabled) {
          background-color: ${palette.grey['300']};
          color: ${palette.text.primary};

          :hover {
            background-color: ${palette.grey['200']};
          }
        }

        :not(:first-child) {
          border-left: solid 1px ${palette.grey['400']};
        }
      }

      .Mui-selected:not(.Mui-disabled) {
        background-color: ${palette.primary.main};
        color: ${palette.common.white};
        :hover {
          background-color: ${palette.primary.main};
          color: ${palette.common.white};
        }
      }

      .Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }

      .MuiFormLabel-root {
        margin: 0 ${spacing(1)}px 0 0;
        @media ${mediaQueries.sm} {
          margin: 0 ${spacing(1)}px 0 ${spacing(2)}px;
        }

        @media ${mediaQueries.md} {
          margin: 0 0 ${spacing(1)}px;
        }
      }

      .MuiButton-label,
      .MuiToggleButton-label {
        line-height: 1;
      }
    `};
`;

StyledSortBy.displayName = 'StyledButton';
