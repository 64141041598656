import PropTypes from 'prop-types';
import React from 'react';

import StyledContainer from './Container.styles';

const Container = ({ fixed, children, ...containerProps }) => (
  <StyledContainer fixed={fixed} {...containerProps}>
    {children}
  </StyledContainer>
);

Container.defaultProps = {
  fixed: false,
  maxWidth: 'xl'
};

Container.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
  fixed: PropTypes.bool
};

export default Container;
