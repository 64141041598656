/* istanbul ignore file */

import styled, { css } from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import React from 'react';

import Typography from 'components/Typography';

export const StyledHeading = styled((props) => (
  <Typography component="p" variant="body1" mb={2} {...props} />
))`
  font-weight: bold;
`;

StyledHeading.displayName = 'StyledHeading';

export const StyledLabel = styled((props) => (
  <Typography component="p" variant="body1" mb={1} {...props} />
))`
  ${({ theme: { palette } }) =>
    css`
      color: ${palette.grey[800]};
    `};
`;

StyledLabel.displayName = 'StyledLabel';

export const StyledEditor = styled((props) => <Box {...props} />)`
  ${({ theme: { palette, spacing, shape } }) =>
    css`
      border: solid 1px ${palette.grey[400]};
      border-radius: ${shape.borderRadius}px;
      padding: ${spacing(2)}px;

      .public-DraftEditor-content {
        min-height: 150px;
      }
      div {
        line-height: 1.5;
      }
    `};
`;

StyledEditor.displayName = 'StyledEditor';

export const StyledVariable = styled.span`
  ${({ theme: { palette } }) =>
    css`
      color: ${palette.text.info};
      background: ${palette.backgrounds.hover};
      padding: 2px 4px;
      font-weight: normal;
    `};
`;
StyledVariable.displayName = 'StyledVariable';

export const StyledVariables = styled.span`
  ${({ theme: { spacing } }) =>
    css`
      ${StyledVariable} {
        cursor: pointer;
        margin: 0 ${spacing(1)}px ${spacing(1)}px 0;
      }
    `};
`;

StyledVariables.displayName = 'StyledVariables';

export const StyledCharacterCount = styled((props) => (
  <Typography component="p" variant="body1" mb={1} {...props} />
))`
  ${({ theme: { palette, spacing } }) =>
    css`
      color: ${palette.text.info};

      .MuiSvgIcon-root {
        margin-right: ${spacing(0.5)}px;
        vertical-align: middle;
        fill: ${palette.text.secondary};
      }
    `};
`;

StyledCharacterCount.displayName = 'StyledButton';
