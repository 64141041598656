import styled, { css } from 'styled-components/macro';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React from 'react';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { rgba } from 'polished';

import Typography from 'components/Typography';
import Grid from 'components/Grid';

export const StyledRowWrapper = styled.div`
  ${({ theme: { palette, shadows }, active, hover = true }) =>
    css`
      overflow: hidden;
      background: ${palette.common.white};
      background: ${active ? palette.backgrounds.hover : ''};
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.02), inset 0px -1px 0px rgba(0, 0, 0, 0.12);

      :hover,
      :focus {
        ${hover && `box-shadow: ${shadows[2]};`}
        position: relative;
        outline: none;
      }
    `};
`;

StyledRowWrapper.displayName = 'StyledRowWrapper';

export const StyledRowSummaryWrapper = styled(
  // eslint-disable-next-line
  ({ borderBottom, expanded, status, ...otherProps }) => <Grid {...otherProps} />
)`
  ${({ theme: { palette, mediaQueries, spacing }, borderBottom = 1, expanded = false, status }) =>
    css`
      border-left-style: solid;
      border-left-width: 3px;
      ${status === 1 && `border-left-color: ${palette.error.main};`}
      ${status === 2 && `border-left-color: ${palette.warning.main};`}
      ${status === 3 && `border-left-color: ${palette.success.main};`}
      padding: ${spacing(0.5)}px ${spacing(0.5)}px ${spacing(1)}px;
      margin: 0;
      width: 100%;
      border-bottom: solid ${borderBottom}px ${palette.grey['300']};
      min-height: 40px;
      flex-wrap: wrap;

      .MuiGrid-root {
        :last-child {
          margin-left: auto;
        }

        @media ${mediaQueries.smMax} {
          :first-child: {
            order: 1;
          }
          :nth-child(2) {
            order: 3;
          }
          :nth-child(3) {
            order: 4;
          }
          :last-child {
            order: 2;
          }
        }
      }

      @media ${mediaQueries.sm} {
        padding: ${spacing(0.5)}px;
      }

      @media ${mediaQueries.md} {
        padding: 0 ${spacing(1)}px;
      }

      .label {
        @media ${mediaQueries.mdMax} {
          display: ${expanded ? 'none' : 'block'};
        }
      }

      a {
        :hover {
          text-decoration: underline;
        }
      }
    `};
`;

StyledRowSummaryWrapper.displayName = 'StyledRowSummaryWrapper';

export const StyledRowLabel = styled((props) => <Typography {...props} />)`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      max-width: calc(100% - 64px);
      font-weight: bold;

      @media ${mediaQueries.md} {
        margin-left: ${spacing(1)}px;
        max-width: calc(100% - 72px);
      }
    `};
`;

StyledRowLabel.displayName = 'StyledRowLabel';

export const StyledActions = styled.div`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .MuiSvgIcon-root {
        width: 1.1em;
        height: 1.1em;
      }

      .call-status {
        @media ${mediaQueries.md} {
          margin-right: ${spacing(1)}px;
        }
      }

      a,
      a .MuiChip-root {
        cursor: pointer;
      }
    `};
`;

StyledActions.displayName = 'StyledActions';

export const StyledExpandLessMore = styled.div`
  ${({ theme: { spacing, palette, mediaQueries }, color }) =>
    css`
      padding: ${spacing(1)}px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${color || palette.grey['600']};

      :hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .expand-collapse {
        cursor: pointer;
        border-radius: 50%;
        border: solid 2px currentColor;
      }

      @media ${mediaQueries.md} {
        padding: ${spacing(1.25)}px;
        margin-right: -${spacing(1)}px;
      }
    `};
`;

StyledExpandLessMore.displayName = 'StyledExpandLessMore';

export const StyledCheckboxWrapper = styled.div`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      pointer-events: all;
      padding: 0;
      width: 110px;
      display: flex;
      justify-content: center;
      @media ${mediaQueries.md} {
        margin-right: ${spacing(1)}px;
      }

      .MuiCheckbox-root {
        padding: 0;

        @media ${mediaQueries.md} {
          padding: ${spacing(1)}px;
        }
      }
    `};
`;

StyledCheckboxWrapper.displayName = 'StyledCheckboxWrapper';

// eslint-disable-next-line
export const StyledDetailsRowWrapper = styled(({ hover = true, active, status, ...otherProps }) => (
  <Grid {...otherProps} />
))`
  ${({ theme: { spacing, palette, mediaQueries }, active, hover, status }) =>
    css`
      padding: ${spacing(0.5)}px;
      margin: 0;
      width: 100%;
      border-bottom: solid 1px ${palette.grey['300']};
      background: ${active === 'true' ? palette.backgrounds.hover : ''};
      flex-wrap: wrap;
      border-left-style: solid;
      border-left-width: 3px;
      ${status === 1 && `border-left-color: ${palette.error.main};`}
      ${status === 2 && `border-left-color: ${palette.warning.main};`}
      ${status === 3 && `border-left-color: ${palette.success.main};`}

      :hover {
        ${hover && ` background: ${palette.backgrounds.hover};`}
      }

      @media ${mediaQueries.md} {
        flex-wrap: nowrap;
        padding: ${spacing(1)}px;
      }

      .MuiList-root {
        padding: 0;
      }

      .MuiListItem-root {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .MuiListItemIcon-root {
          min-width: 0;
          padding-right: ${spacing(1)}px;
        }

        .MuiListItemText-root {
          margin-top: 0;
          padding-top: 0;
          padding-bottom: 0;
        }

        .MuiTypography-body1 {
          max-width: calc(100% - 20px);
        }
      }

      a {
        color: ${palette.text.primary};
      }
    `};
`;

StyledDetailsRowWrapper.displayName = 'StyledDetailsRowWrapper';

// eslint-disable-next-line
export const StyledCheckbox = styled(({ small, ...props }) => (
  <Checkbox {...props} checkedIcon={<CheckIcon />} icon={<SvgIcon />} />
))`
  ${({ theme: { spacing, shape, palette }, small }) => css`
    .MuiSvgIcon-root {
      border: 2px solid ${palette.grey['600']};
      border-radius: ${shape.borderRadius / 2}px;
      height: ${small ? `${spacing(2)}px` : '1em'};
      width: ${small ? `${spacing(2)}px` : '1em'};
    }

    &.Mui-disabled {
      opacity: 1;

      .MuiSvgIcon-root {
        background: ${palette.grey['200']};
        fill: ${palette.grey['400']};
        border-color: ${palette.grey['500']};
      }
    }

    &.Mui-checked {
      .MuiSvgIcon-root {
        background: ${palette.backgrounds.checkbox};
        fill: ${palette.text.info};
        border-color: ${palette.text.info};
      }

      &.Mui-disabled {
        .MuiSvgIcon-root {
          background: ${palette.grey['200']};
          fill: ${palette.grey['400']};
          border-color: ${palette.grey['500']};
        }
      }
    }

    :hover {
      .MuiSvgIcon-root {
        background: ${palette.backgrounds.checkbox};
      }
    }

    + .MuiFormControlLabel-label {
      margin-left: ${spacing(0.5)}px;
    }

    .MuiIconButton-label {
      background-color: ${palette.common.white};
    }
  `};
`;

export const StyledToggleButtonGroup = styled((props) => <ToggleButtonGroup {...props} />)`
  ${({ theme: { spacing, palette } }) => css`
    .MuiToggleButton-root {
      text-transform: none;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.05);
      transition: all 0.1 ease-in-out;
      border: none;
      margin-right: 2px;
      color: ${palette.grey['600']};

      :hover {
        background-color: ${rgba(palette.backgrounds.infoDark, 0.5)};
      }

      &.Mui-selected:not(.Mui-disabled) {
        background-color: ${palette.backgrounds.infoDark};
        color: ${palette.text.info};
      }

      &.Mui-disabled {
        opacity: 0.5;
      }
    }

    .MuiToggleButton-sizeSmall {
      padding: ${spacing(1)}px ${spacing(1.5)}px;
    }
  `};
`;

export const StyledListLabel = styled.strong`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      display: none;
      margin: 0 0 ${spacing(1)}px;

      @media ${mediaQueries.mdMax} {
        display: block;
      }
    `};
`;

// eslint-disable-next-line
export const StyledSelect = styled(({ active, ...props }) => (
  <Select
    {...props}
    input={<OutlinedInput />}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null
    }}
  />
))`
  ${({ theme: { spacing, palette }, active }) =>
    css`
      background: ${palette.common.white};

      &.MuiOutlinedInput-adornedEnd {
        padding-right: 0;
      }

      ${active &&
      `.MuiOutlinedInput-notchedOutline {
        border: solid 2px ${palette.text.primary};
      }`}

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: solid 2px ${palette.text.primary};
      }

      .MuiSelect-selectMenu {
        padding: 0;

        :focus {
          background: ${palette.common.white};
        }
      }

      .MuiSelect-icon {
        right: ${spacing(1)}px;
      }

      .MuiInputAdornment-positionEnd {
        cursor: pointer;
        margin-left: 0;
        position: absolute;
        right: ${spacing(4)}px;
        height: auto;
        max-height: none;
        padding: ${spacing(0.5)}px;
        opacity: 0.5;
        border-radius: 50%;

        :hover {
          opacity: 0.8;
          background-color: ${palette.grey['100']};
        }
      }
    `};
`;

StyledSelect.displayName = 'StyledSelect';

export const StyledSelectValue = styled.div`
  ${({ theme: { spacing, palette } }) =>
    css`
      background-color: ${palette.common.white};
      padding: ${spacing(1.75)}px 0 ${spacing(1.75)}px ${spacing(2)}px;
    `};
`;

StyledSelect.displayName = 'StyledSelect';
