import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NoDataIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 24 24'}>
      <path d="M6.82996 4L14.83 12H20V14H16.83L22 19.17V4H6.82996Z" />
      <path d="M1.03998 3.86922L1.99998 4.82922V19.9992H17.17L20.13 22.9592L21.54 21.5492L2.44998 2.44922L1.03998 3.86922ZM3.99998 11.9992H7.99998V13.9992H3.99998V11.9992ZM3.99998 15.9992H13.17L14 16.8292V17.9992H3.99998V15.9992Z" />
    </SvgIcon>
  );
};

export { NoDataIcon };
