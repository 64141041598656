import Drawers from 'app/constants/drawers';

const createDrawerStore = (set) => ({
  activeDrawerType: null,

  showFilterDrawer: () => {
    set((state) => {
      state.drawerStore.activeDrawerType = Drawers.FILTERS;
    });
  },
  showSearchDrawer: () => {
    set((state) => {
      state.drawerStore.activeDrawerType = Drawers.SEARCH;
    });
  },
  hideDrawer() {
    set((state) => {
      state.drawerStore.activeDrawerType = null;
    });
  }
});

export { createDrawerStore };
