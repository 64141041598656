import styled, { css } from 'styled-components/macro';

export const StyledSMSSendSuccess = styled.div`
  ${({ theme: { spacing, palette } }) => css`
    .MuiSvgIcon-root {
      fill: ${palette.text.secondary};
      margin-right: ${spacing(1)}px;
    }

    .MuiChip-root {
      color: ${palette.text.info};
      cursor: default;
      background: ${palette.backgrounds.hover};
    }

    .sms-icon {
      margin-bottom: -5px;
    }

    .MuiDialogContent-root {
      .MuiList-root {
        .MuiListItem-root {
          padding-top: ${spacing(1)}px;
          padding-bottom: ${spacing(1)}px;
          border-top: solid 1px ${palette.grey[200]};
        }
      }
    }
  `};
`;
