import styled, { css } from 'styled-components/macro';
import React from 'react';

import Grid from 'components/Grid';

export const SearchSelectionsWrapper = styled.div`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      align-items: flex-start;
      margin-top: ${spacing(1)}px;

      @media ${mediaQueries.md} {
        margin-top: 0;
      }

      .MuiButtonBase-root {
        margin-bottom: ${spacing(2)}px;
      }
    `};
`;

SearchSelectionsWrapper.displayName = 'SearchSelectionsWrapper';

export const ActiveFilters = styled((props) => <Grid {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 1;
`;

ActiveFilters.displayName = 'ActiveFilters';
