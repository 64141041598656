import styled, { css } from 'styled-components/macro';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { rgba } from 'polished';

// eslint-disable-next-line
export default styled((props) => <Dialog {...props} />)`
  ${({ theme: { spacing, shape, mediaQueries, palette, shadows } }) => css`
    position: relative;

    .MuiBackdrop-root {
      background-color: ${rgba(palette.common.white, 0.6)};
    }

    .MuiDialog-paper {
      box-shadow: ${shadows[1]};
      border-radius: ${shape.borderRadiusLarge}px;
      background: ${palette.common.white};
      max-height: calc(100vh - 150px);
      margin-top: 70px;
    }

    .MuiDialogTitle-root,
    .MuiDialogContent-root {
      padding: 0;
    }

    .MuiDialogTitle-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${spacing(4)}px ${spacing(2)}px ${spacing(1)}px;

      .MuiSvgIcon-root {
        margin-right: ${spacing(2)}px;
        font-size: 1.875rem;

        @media ${mediaQueries.smMax} {
          margin-right: 0;
          margin-bottom: ${spacing(1)}px;
        }
      }

      @media ${mediaQueries.sm} {
        flex-direction: row;
        padding-left: ${spacing(4)}px;
        padding-right: ${spacing(4)}px;
      }
    }

    .MuiDialogContent-root {
      padding: ${spacing(1)}px ${spacing(2)}px;
      .MuiListItem-dense {
        padding-top: 0;
        padding-bottom: 0;

        .MuiListItemText-dense {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .MuiTypography-root {
        line-height: 2;
      }

      @media ${mediaQueries.sm} {
        padding-left: ${spacing(4)}px;
        padding-right: ${spacing(4)}px;
      }
    }

    .MuiDialogActions-root {
      padding: ${spacing(3)}px ${spacing(2)}px ${spacing(4)}px;
      justify-content: center;
      flex-direction: column;

      > * {
        margin-bottom: ${spacing(2)}px;
        width: 100%;
      }

      @media ${mediaQueries.sm} {
        flex-direction: row;
        justify-content: flex-start;
        padding-left: ${spacing(4)}px;
        padding-right: ${spacing(4)}px;

        > * {
          margin-bottom: 0;
          width: auto;
        }
      }

      .MuiButtonBase-root {
        text-transform: none;
        line-height: 1.1;
      }
    }

    .MuiDialogActions-spacing {
      > :not(:first-child) {
        @media ${mediaQueries.smMax} {
          margin-left: 0;
        }
      }
    }
  `};
`;
