import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 18 12'}>
      <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" />
    </SvgIcon>
  );
};

export { FilterIcon };
