import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrendLowerIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 12 9'}>
      <path d="M6 8.30768L12 -1.43945e-05H0L6 8.30768Z" />
    </SvgIcon>
  );
};

export { TrendLowerIcon };
