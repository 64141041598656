import InputLabel from '@material-ui/core/InputLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTheme } from '@material-ui/core/styles';
// import isEmpty from 'lodash.isempty';

import { StyledSortBy } from './SortBy.styles';
import { useStore } from 'store';

const ConnectedSortBy = () => {
  const theme = useTheme();
  const { setSortBy, sortByOptions, sortBy } = useStore((state) => state.algoliaStore);

  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  if (!sortBy) {
    return null;
  }

  // TODO: connect algolia and determine
  // const noSearchResults = isEmpty(searchResults);

  const changeSortingOption = (event, value) => {
    setSortBy(value);
  };

  return (
    <StyledSortBy className="sortby-toggle">
      <InputLabel htmlFor="sortby">{isTablet ? 'Sort' : 'Sort by'}</InputLabel>
      <ToggleButtonGroup
        size="medium"
        value={sortBy}
        exclusive
        onChange={changeSortingOption}
        data-ref={'sort-by-toggle'}
        id="sortby"
      >
        {sortByOptions.map((option) => {
          return (
            <ToggleButton
              key={option[1]}
              value={option[1]}
              size="large"
              // disabled={noSearchResults}
            >
              {option[0]}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </StyledSortBy>
  );
};

export default ConnectedSortBy;
