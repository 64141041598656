import { apiEndpoints } from 'app/constants/apiEndpoints';
import { errorMessages } from 'app/constants/errorMessages';
import { getErrorMessage } from '../utils/apiUtils';
import { transformTeachingPeriods } from 'utils/transformApiResponseUtils';

const createTeachingPeriodsStore = (set, get) => ({
  isFetchingTeachingPeriods: false,
  teachingPeriods: [],
  selectedTeachingPeriod: null,

  fetchTeachingPeriods: async () => {
    set((state) => {
      state.teachingPeriodsStore.isFetchingTeachingPeriods = true;
    });

    const { apiRequest } = get().actions;
    const { courseId } = get().authStore.userSession;

    const response = await apiRequest(apiEndpoints.teachingPeriods(courseId));

    const handleError = (error) => {
      set((state) => {
        state.teachingPeriodsStore.isFetchingTeachingPeriods = false;
        state.error = error;
      });
    };

    if (response.status !== 200) {
      const error = getErrorMessage(response.status);
      handleError(error);
      return;
    }

    if (!response.data.periods || !response.data.periods.length > 0) {
      const error = getErrorMessage({
        status: 404,
        notFoundMessage: errorMessages.noTeachingPeriods
      });
      handleError(error);
      return;
    }

    const teachingPeriods = transformTeachingPeriods(response.data);

    set((state) => {
      state.teachingPeriodsStore = {
        ...get().teachingPeriodsStore,
        teachingPeriods,
        selectedTeachingPeriod: teachingPeriods.periods[0],
        isFetchingTeachingPeriods: false
      };
    });
  },

  setTeachingPeriods(teachingPeriods) {
    set((state) => {
      state.teachingPeriodsStore = {
        ...get().teachingPeriodsStore,
        teachingPeriods,
        selectedTeachingPeriod: teachingPeriods.periods[0],
        isFetchingTeachingPeriods: false
      };
    });
  },

  setIsFetchingTeachingPeriods(isFetchingTeachingPeriods) {
    set((state) => {
      state.teachingPeriodsStore.isFetchingTeachingPeriods = isFetchingTeachingPeriods;
    });
  },

  selectTeachingPeriod(selectedTeachingPeriod) {
    set((state) => {
      state.teachingPeriodsStore.selectedTeachingPeriod = selectedTeachingPeriod;
    });
  }
});

export { createTeachingPeriodsStore };
