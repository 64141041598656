import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Drawers from '../../app/constants/drawers';
import Filters from '../Filters';
import { ConnectedSearch } from '../Search/Search';
import { StyledDrawer } from './Drawer.styles';
import { useStore } from 'store';
import VirtualSearchWidgets from '../VirtualSearchWidgets';

const drawerContentMap = (onClose) => ({
  [Drawers.FILTERS]: <Filters isDrawer={true} onCloseDrawer={onClose} />,
  [Drawers.SEARCH]: <ConnectedSearch onCloseDrawer={onClose} />
});

const Drawer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { activeDrawerType, hideDrawer } = useStore((state) => state.drawerStore);

  const isDrawerOpen = Boolean(activeDrawerType);

  return (
    <>
      <StyledDrawer
        anchor={isSmallScreen ? 'top' : 'left'}
        data-ref="drawer"
        open={isDrawerOpen}
        onClose={hideDrawer}
        ModalProps={{ BackdropProps: { invisible: true } }}
        data-testid="drawer"
      >
        {drawerContentMap(hideDrawer)[activeDrawerType]}
      </StyledDrawer>
      {isSmallScreen && !isDrawerOpen && <VirtualSearchWidgets />}
    </>
  );
};

export default Drawer;
