import PropTypes from 'prop-types';
import React from 'react';

import StyledTypography from './Typography.styles';

const Typography = ({ children, variant, display, ...typographyProps }) => {
  return (
    <StyledTypography variant={variant} display={display} {...typographyProps}>
      {children}
    </StyledTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Typography.defaultProps = {
  variant: 'body1',
  display: 'initial'
};

export default Typography;
