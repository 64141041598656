import styled, { css } from 'styled-components/macro';

// eslint-disable-next-line
export const StyledSMSPreview = styled.div`
  ${({ theme: { spacing, palette } }) => css`
    padding-top: ${spacing(2)}px;

    .MuiSvgIcon-root {
      fill: ${palette.text.secondary};
      margin-right: ${spacing(1)}px;
    }

    .MuiChip-root {
      color: ${palette.text.info};
      cursor: default;
      background: ${palette.backgrounds.hover};
    }

    .sms-icon {
      margin-bottom: -5px;
    }
  `};
`;
