import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import React from 'react';
import { spacing as materialSpacing } from '@material-ui/system';

export const StyledButton = styled((props) => <Button {...props} />)`
  ${({ theme: { spacing, palette } }) =>
    css`
      color: ${palette.grey['800']};
      font-weight: bold;
      text-transform: none;
      text-decoration: underline;
      padding: 0 ${spacing(1)}px;

      :hover {
        text-decoration: none;
        background-color: transparent;
      }
    `}
  ${materialSpacing}
`;

StyledButton.displayName = 'StyledButton';
