import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AddIcon from '@material-ui/icons/Add';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmailIcon from '@material-ui/icons/Email';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GroupIcon from '@material-ui/icons/Group';
import LanguageIcon from '@material-ui/icons/Language';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from 'prop-types';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import RepeatIcon from '@material-ui/icons/Repeat';
import SearchIcon from '@material-ui/icons/Search';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import SmsIcon from '@material-ui/icons/Sms';
import StarIcon from '@material-ui/icons/Star';
import TimerOffIcon from '@material-ui/icons/TimerOff';

import { StickyNoteIcon } from './assets/StickyNoteIcon';
import { SuccessIcon } from './assets/SuccessIcon';
import { NoSearchIcon } from './assets/NoSearchIcon';
import { NoDataIcon } from './assets/NoDataIcon';
import { FollowTheSignsIcon } from './assets/FollowTheSignsIcon';
import { FilterIcon } from './assets/FilterIcon';
import { CogsIcon } from './assets/CogsIcon';
import { TrendHigherIcon } from './assets/TrendHigherIcon';
import { TrendLowerIcon } from './assets/TrendLowerIcon';
import { TrendSteadyIcon } from './assets/TrendSteadyIcon';
import { PostsIcon } from './assets/PostsIcon';
import { ICON_TYPES } from './icon-types';

const Svg = ({ name, ...iconProps }) => {
  switch (name) {
    case ICON_TYPES.GENERIC_ERROR:
      return <CogsIcon {...iconProps} />;
    case ICON_TYPES.FILTER:
      return <FilterIcon {...iconProps} />;
    case ICON_TYPES.SUCCESS:
      return <SuccessIcon {...iconProps} />;
    case ICON_TYPES.NO_CONNECTION:
      return <LinkOffIcon {...iconProps} />;
    case ICON_TYPES.NO_ACCESS:
      return <NoEncryptionIcon {...iconProps} />;
    case ICON_TYPES.SEARCH:
      return <SearchIcon {...iconProps} />;
    case ICON_TYPES.SMS:
      return <SmsIcon {...iconProps} />;
    case ICON_TYPES.PHONE_DISABLED:
      return <PhoneDisabledIcon {...iconProps} />;
    case ICON_TYPES.PHONE_SETTINGS:
      return <SettingsPhoneIcon {...iconProps} />;
    case ICON_TYPES.ADD:
      return <AddIcon {...iconProps} />;
    case ICON_TYPES.CHECK:
      return <CheckIcon {...iconProps} />;
    case ICON_TYPES.NO_DATA:
      return <NoDataIcon {...iconProps} />;
    case ICON_TYPES.NO_SEARCH_RESULTS:
      return <NoSearchIcon {...iconProps} />;
    case ICON_TYPES.ERROR_EXCLAMATION:
      return <ErrorOutlineIcon {...iconProps} />;
    case ICON_TYPES.EXPAND_LESS:
      return <ExpandLessIcon {...iconProps} />;
    case ICON_TYPES.EXPAND_MORE:
      return <ExpandMoreIcon {...iconProps} />;
    case ICON_TYPES.TIME_OFF:
      return <TimerOffIcon {...iconProps} />;
    case ICON_TYPES.ALARM:
      return <AccessAlarmIcon {...iconProps} />;
    case ICON_TYPES.SMS_FAILED:
      return <SmsFailedIcon {...iconProps} />;
    case ICON_TYPES.CONTINUING_STUDENT:
      return <FollowTheSignsIcon {...iconProps} />;
    case ICON_TYPES.MICROPHONE_ON:
      return <MicIcon {...iconProps} />;
    case ICON_TYPES.MICROPHONE_OFF:
      return <MicOffIcon {...iconProps} />;
    case ICON_TYPES.ADD_NOTE:
      return <NoteAddIcon {...iconProps} />;
    case ICON_TYPES.STICKY_NOTE:
      return <StickyNoteIcon {...iconProps} />;
    case ICON_TYPES.PHONE:
      return <PhoneIcon {...iconProps} />;
    case ICON_TYPES.CONTACTS:
      return <ContactsIcon {...iconProps} />;
    case ICON_TYPES.CALENDAR:
      return <CalendarTodayIcon {...iconProps} />;
    case ICON_TYPES.REFRESH:
      return <RefreshIcon {...iconProps} />;
    case ICON_TYPES.COPY_PHONE_NUMBER:
      return <PhoneForwardedIcon {...iconProps} />;
    case ICON_TYPES.ACCESSIBILITY_NEW:
      return <AccessibilityNewIcon {...iconProps} />;
    case ICON_TYPES.CHECK_CIRCLE_OUTLINE:
      return <CheckCircleOutlineIcon {...iconProps} />;
    case ICON_TYPES.CONTACT_MAIL:
      return <ContactMailIcon {...iconProps} />;
    case ICON_TYPES.DIRECTIONS_WALK:
      return <DirectionsWalkIcon {...iconProps} />;
    case ICON_TYPES.EMAIL:
      return <EmailIcon {...iconProps} />;
    case ICON_TYPES.FINGERPRINT:
      return <FingerprintIcon {...iconProps} />;
    case ICON_TYPES.GROUP:
      return <GroupIcon {...iconProps} />;
    case ICON_TYPES.LANGUAGE:
      return <LanguageIcon {...iconProps} />;
    case ICON_TYPES.LIBRARY_BOOKS:
      return <LibraryBooksIcon {...iconProps} />;
    case ICON_TYPES.LOCATION_ON:
      return <LocationOnIcon {...iconProps} />;
    case ICON_TYPES.LOCAL_LIBRARY:
      return <LocalLibraryIcon {...iconProps} />;
    case ICON_TYPES.MENU_BOOK:
      return <MenuBookIcon {...iconProps} />;
    case ICON_TYPES.NEW_RELEASES:
      return <NewReleasesIcon {...iconProps} />;
    case ICON_TYPES.REPEAT:
      return <RepeatIcon {...iconProps} />;
    case ICON_TYPES.STAR:
      return <StarIcon {...iconProps} />;
    case ICON_TYPES.POSTS:
      return <PostsIcon {...iconProps} />;
    case ICON_TYPES.TREND_HIGHER:
      return <TrendHigherIcon {...iconProps} />;
    case ICON_TYPES.TREND_LOWER:
      return <TrendLowerIcon {...iconProps} />;
    case ICON_TYPES.TREND_STEADY:
      return <TrendSteadyIcon {...iconProps} />;

    default:
      return <div />;
  }
};

Svg.propTypes = {
  name: PropTypes.string
};

const Icon = ({ name, ...iconProps }) => {
  return Svg({ name, ...iconProps });
};

Icon.propTypes = {
  name: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string
};

Icon.defaultProps = {
  width: 24
};

export { Icon };
