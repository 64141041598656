import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Icon, ICON_TYPES } from 'components/Icon';
import { StyledButton, StyledGrid, StyledMarkedCounter } from './SMSButton.styles';
import { getSmsPendingText } from 'utils/dataUtils';
import Grid from 'components/Grid';
import LoadingSpinner from 'components/LoadingSpinner';
import { Routes } from 'app/routes';
import SimpleButton from 'components/SimpleButton';
import Tooltip from 'components/Tooltip';
import { helpMessages } from 'app/constants/helpMessages';
import { useStore } from 'store';

// We can move this to be dynamic if required
const MAX_SELECTIONS = 500;

const SMSButton = ({ showSelectedLabel, size }) => {
  const studentsSelectedForSmsLength = useStore((state) => state.smsStore.studentsToSms.length);
  const isFetchingSms = useStore((state) => state.smsStore.isFetchingSms);
  const fetchSmsPendingCount = useStore((state) => state.smsStore.fetchSmsPendingCount);
  const smsPendingLength = useStore((state) => state.smsStore.smsPending.length);
  const smsPendingCount = useStore((state) => state.smsStore.smsPendingCount);
  const triggerRefetch = useStore((state) => state.algoliaStore.triggerRefetch);
  // const fetchStudents = useStore((state) => state.studentsStore.fetchStudents);

  const isButtonDisabled =
    studentsSelectedForSmsLength === 0 || studentsSelectedForSmsLength > MAX_SELECTIONS;

  return (
    <>
      {isFetchingSms && <LoadingSpinner />}
      <StyledGrid container spacing={1} data-ref="sms-button-wrapper">
        {showSelectedLabel && (
          <>
            {/* Original Implementation, can be removed when algolia migration done */}
            {smsPendingLength > 0 && (
              <Grid item>
                <StyledMarkedCounter data-ref="sms-pending-marker" status={2}>
                  <InputAdornment position="start">
                    <Icon name={ICON_TYPES.REFRESH} />
                  </InputAdornment>
                  {getSmsPendingText(smsPendingLength)}
                  <SimpleButton
                    onClick={() => {
                      triggerRefetch();
                      fetchSmsPendingCount();
                    }}
                    data-ref="refresh-data"
                    id="refresh-data"
                    label={'refresh'}
                  />
                </StyledMarkedCounter>
              </Grid>
            )}
            {/* Implementation for Algolia system */}
            {smsPendingCount > 0 && (
              <Grid item>
                <StyledMarkedCounter data-ref="sms-pending-marker" status={2}>
                  <InputAdornment>
                    <Icon name={ICON_TYPES.REFRESH} />
                  </InputAdornment>
                  {getSmsPendingText(smsPendingCount)}
                  <SimpleButton
                    onClick={() => {
                      triggerRefetch();
                      fetchSmsPendingCount();
                    }}
                    data-ref="refresh-data"
                    id="refresh-data"
                    label={'refresh'}
                  />
                </StyledMarkedCounter>
              </Grid>
            )}
            {/* Not sure if we can simply remove this */}
            {/* {smsFailedLength > 0 && (
              <Grid item>
                <StyledMarkedCounter data-ref="sms-failed-marker" status={1}>
                  <InputAdornment>
                    <Icon name={ICON_TYPES.ERROR_EXCLAMATION} />
                  </InputAdornment>
                  {getSmsFailedLength(smsFailedLength)}
                </StyledMarkedCounter>
              </Grid>
            )} */}
          </>
        )}
        <Grid item className="sms-button">
          <Tooltip
            content={
              studentsSelectedForSmsLength > MAX_SELECTIONS
                ? helpMessages.maxSmsSelections(MAX_SELECTIONS)
                : helpMessages.disabledSmsSave
            }
            disabled={!isButtonDisabled}
          >
            <div>
              <StyledButton
                size={size}
                disabled={isButtonDisabled}
                disableElevation
                variant="contained"
                color="primary"
                component={RouterLink}
                to={Routes.SendSMS}
              >
                Send SMS
              </StyledButton>
            </div>
          </Tooltip>
        </Grid>
      </StyledGrid>
    </>
  );
};

SMSButton.propTypes = {
  showSelectedLabel: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small'])
};

SMSButton.defaultProps = {
  showSelectedLabel: true,
  size: 'large'
};

export default SMSButton;
