import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'components/Icon';
import Typography from 'components/Typography';
import { useStore } from 'store';

const ModalContentAppError = ({ onClose }) => {
  const { title, message, actions, icon } = useStore((state) => state.error) || {};
  const handleError = useStore((state) => state.actions.handleError);

  const handleOnClose = () => {
    handleError(null);
    onClose();
  };

  return (
    <>
      <DialogTitle disableTypography>
        {icon ? <Icon name={icon} /> : <NoEncryptionIcon />}
        {title && (
          <Typography variant="h3" component="p">
            <span data-ref="modal-title">{title}</span>
          </Typography>
        )}
      </DialogTitle>

      {message && (
        <DialogContent>
          <Typography mb={2}>
            <span data-ref="error-message" dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        </DialogContent>
      )}

      {actions && (
        <DialogActions>
          {actions.map((action, index) => (
            <Button
              key={index}
              disableElevation
              size="large"
              variant={action.primary ? 'contained' : 'outlined'}
              color={action.primary ? 'primary' : 'default'}
              data-ref={`action-${index}-button`}
              {...(action.url ? { href: action.url } : { onClick: handleOnClose })}
              {...(action.url && { target: '_blank' })}
            >
              {action.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </>
  );
};

ModalContentAppError.displayName = 'ModalContentAppError';

ModalContentAppError.propTypes = {
  onClose: PropTypes.func
};

ModalContentAppError.defaultProps = {
  onClose: () => {}
};

export default ModalContentAppError;
