import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CogsIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 21 20'}>
      <path d="M12.17 11.71L13.57 9.29C13.66 9.14 13.62 8.95 13.49 8.84L12.01 7.68C12.04 7.46 12.06 7.23 12.06 7C12.06 6.77 12.04 6.54 12.01 6.31L13.49 5.15C13.62 5.04 13.66 4.85 13.57 4.7L12.17 2.28C12.08 2.13 11.9 2.07 11.74 2.13L10 2.83C9.64 2.55 9.25 2.32 8.82 2.14L8.56 0.29C8.53 0.13 8.38 0 8.21 0H5.41C5.24 0 5.09 0.13 5.06 0.3L4.8 2.15C4.38 2.33 3.98 2.56 3.62 2.84L1.88 2.14C1.72 2.08 1.54 2.14 1.45 2.29L0.0500033 4.71C-0.0399967 4.86 3.48687e-06 5.05 0.130003 5.16L1.61 6.32C1.58 6.54 1.56 6.77 1.56 7C1.56 7.23 1.58 7.46 1.61 7.69L0.130003 8.85C3.48687e-06 8.96 -0.0399967 9.15 0.0500033 9.3L1.45 11.72C1.54 11.87 1.72 11.93 1.88 11.87L3.62 11.17C3.98 11.45 4.37 11.68 4.8 11.86L5.06 13.71C5.09 13.87 5.24 14 5.41 14H8.21C8.38 14 8.53 13.87 8.56 13.7L8.82 11.85C9.24 11.67 9.64 11.44 10 11.16L11.74 11.86C11.9 11.92 12.08 11.86 12.17 11.71ZM6.81 9C5.71 9 4.81 8.1 4.81 7C4.81 5.9 5.71 5 6.81 5C7.91 5 8.81 5.9 8.81 7C8.81 8.1 7.91 9 6.81 9Z" />
      <path d="M19.92 16.67L18.96 15.93C18.98 15.79 19 15.64 19 15.49C19 15.34 18.99 15.19 18.96 15.05L19.91 14.31C19.99 14.24 20.02 14.12 19.96 14.02L19.06 12.47C19.01 12.37 18.89 12.34 18.78 12.37L17.67 12.82C17.44 12.64 17.19 12.49 16.91 12.38L16.74 11.2C16.73 11.08 16.63 11 16.53 11H14.74C14.63 11 14.53 11.08 14.52 11.19L14.35 12.37C14.08 12.49 13.82 12.63 13.59 12.81L12.48 12.36C12.38 12.32 12.26 12.36 12.2 12.46L11.3 14.01C11.25 14.11 11.26 14.23 11.35 14.3L12.3 15.04C12.28 15.18 12.27 15.33 12.27 15.48C12.27 15.63 12.28 15.78 12.3 15.92L11.35 16.66C11.27 16.73 11.24 16.85 11.3 16.95L12.2 18.5C12.25 18.6 12.37 18.63 12.48 18.6L13.59 18.15C13.82 18.33 14.07 18.48 14.35 18.59L14.52 19.77C14.54 19.88 14.63 19.96 14.74 19.96H16.53C16.64 19.96 16.74 19.88 16.75 19.77L16.92 18.59C17.19 18.47 17.45 18.33 17.67 18.15L18.79 18.6C18.89 18.64 19.01 18.6 19.07 18.5L19.97 16.95C20.03 16.86 20 16.74 19.92 16.67ZM15.63 16.83C14.89 16.83 14.28 16.23 14.28 15.48C14.28 14.73 14.88 14.13 15.63 14.13C16.38 14.13 16.98 14.73 16.98 15.48C16.98 16.23 16.37 16.83 15.63 16.83Z" />
    </SvgIcon>
  );
};

export { CogsIcon };
