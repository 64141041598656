import PropTypes from 'prop-types';
import React from 'react';

import StyledGrid from './Grid.styles';

const Grid = ({ children, ...gridProps }) => <StyledGrid {...gridProps}>{children}</StyledGrid>;

Grid.propTypes = {
  children: PropTypes.node.isRequired
};

export default Grid;
