import styled, { css } from 'styled-components/macro';
import Backdrop from '@material-ui/core/Backdrop';
import React from 'react';

export const StyledBackdrop = styled((props) => <Backdrop {...props} />)`
  ${({ theme: { zIndex, palette } }) =>
    css`
      z-index: ${zIndex.drawer + 1};
      color: ${palette.common.white};
    `}
`;

StyledBackdrop.displayName = 'Backdrop';
