import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash.isempty';
import { connectSearchBox } from 'react-instantsearch-dom';

import Drawers from 'app/constants/drawers';
import SimpleButton from 'components/SimpleButton';
import { StyledSearch } from './Search.styles';
import { useStore } from 'store';

const SEARCH_TIMEOUT = 500;

const Search = ({ onCloseDrawer }) => {
  const { clearSearchTerm, setIsSearching, searchForStudent, searchTerm, isSearching } = useStore(
    (state) => state.searchStore
  );
  const isSearchDrawerOpen = useStore(
    (state) => state.drawerStore.activeDrawerType === Drawers.SEARCH
  );
  const noSearchResults = useStore((state) => isEmpty(state.searchStore.searchResults));

  const [searchInput, setSearchInput] = useState(searchTerm);
  const searchInputRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const onChangeSearchQuery = (value) => {
    if (!isSearching) {
      setIsSearching(true);
    }

    setSearchInput(value);
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      searchForStudent(searchInput);
    }, SEARCH_TIMEOUT);

    return () => clearTimeout(delayedSearch);
  }, [searchInput]);

  useEffect(() => {
    setSearchInput(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (isSearchDrawerOpen) {
      searchInputRef.current.focus();
    }
  }, [isSearchDrawerOpen]);

  return (
    <StyledSearch className="search-bar" isDrawerOpen={isSearchDrawerOpen}>
      <TextField
        fullWidth
        variant="outlined"
        color="secondary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchInput && (
            <InputAdornment position="end">
              <CloseIcon onClick={clearSearchTerm} data-ref="clear-search-button" />
            </InputAdornment>
          )
        }}
        placeholder="Search student"
        name="query"
        value={searchInput}
        onChange={(e) => onChangeSearchQuery(e.target.value)}
        disabled={!searchInput && noSearchResults}
        data-ref="search-input"
        inputRef={searchInputRef}
        inputProps={{ 'aria-label': 'Search student' }}
      />
      {isSmallScreen && <SimpleButton onClick={onCloseDrawer} label="Hide search" mt={2} p={0} />}
    </StyledSearch>
  );
};

Search.defaultProps = {
  onCloseDrawer: () => {}
};

Search.propTypes = {
  onCloseDrawer: PropTypes.func
};

export default Search;

const ConnectedSearch = connectSearchBox(
  ({ onCloseDrawer, refine, currentRefinement: searchInput }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
    const isSearchDrawerOpen = useStore(
      (state) => state.drawerStore.activeDrawerType === Drawers.SEARCH
    );

    const clearSearchTerm = () => refine('');

    return (
      <StyledSearch className="search-bar" isDrawerOpen={isSearchDrawerOpen}>
        <TextField
          fullWidth
          variant="outlined"
          color="secondary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchInput && (
              <InputAdornment position="end">
                <CloseIcon onClick={clearSearchTerm} data-ref="clear-search-button" />
              </InputAdornment>
            )
          }}
          placeholder="Search student"
          name="query"
          value={searchInput}
          onChange={(e) => refine(e.target.value)}
          // disabled={!searchInput && noSearchResults}
          inputProps={{ 'aria-label': 'Search student' }}
        />
        {isSmallScreen && <SimpleButton onClick={onCloseDrawer} label="Hide search" mt={2} p={0} />}
      </StyledSearch>
    );
  }
);

export { ConnectedSearch, Search };
