import orderBy from 'lodash.orderby';

export const fuseGet = (obj, path) => {
  const value = '';
  if (path[0] !== 'fullName') {
    const strippedValue = Array.isArray(value)
      ? value.map((val) => val.replace(/\s/g, ''))
      : value && value.replace(/\s/g, '');

    return strippedValue;
  }
  return value;
};

export const searchOptions = {
  keys: [
    'fullName',
    'elaName',
    'learningGroup',
    'riskLevel',
    'riskReasons',
    'riskStatus',
    'otherDetails',
    'callStatusLabel'
  ],
  shouldSort: false,
  minMatchCharLength: 2,
  useExtendedSearch: true,
  getFn: fuseGet,
  threshold: 0.4
};

export const createSearchIndex = (data) => Object.assign(Object, data);

const parseFilters = (filters) =>
  filters && filters.length > 0
    ? filters.reduce((filtersMap, filter) => {
        const [key, value] = Object.entries(filter)[0];
        const strippedValue = value && value.replace(/\s/g, '');
        // eslint-disable-next-line no-param-reassign
        filtersMap[key] = filtersMap[key]
          ? `${filtersMap[key]} | '${strippedValue}`
          : `'${strippedValue}`;

        return filtersMap;
      }, {})
    : undefined;

const getSorting = ({ key, order }) => {
  const primaryKey = key;
  const primaryOrder = order;

  const secondaryKey = primaryKey === 'riskStatus' ? 'riskReasonsNumber' : 'riskStatus';
  const secondaryOrder = primaryKey === 'riskStatus' ? 'desc' : 'asc';

  return { primaryKey, primaryOrder, secondaryKey, secondaryOrder };
};

export const orderResults = (data, sortBy) => {
  const { primaryKey, primaryOrder, secondaryKey, secondaryOrder } = getSorting(sortBy);

  return orderBy(
    data,
    [primaryKey, secondaryKey, 'fullName'],
    [primaryOrder, secondaryOrder, 'asc']
  );
};

export const getSearchResults = ({ searchIndex, data, filters, searchTerm, sortBy }) => {
  if (!data) {
    return null;
  }

  const searchFilters = parseFilters(filters);
  const searchCriteria = {
    $and: [
      ...(searchFilters ? [searchFilters] : []),
      ...(searchTerm ? [{ fullName: searchTerm }] : [])
    ]
  };
  const searchResults =
    searchTerm || searchFilters
      ? searchIndex
          .search(searchCriteria)
          .map((result) => ({ ...result.item, refIndex: result.refIndex }))
      : data;

  return orderResults(searchResults, sortBy);
};
