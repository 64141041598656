import { createTheme } from '@material-ui/core/styles';
import { brand } from 'brand';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: brand.colors.primary
    },
    secondary: {
      main: '#000'
    },
    error: {
      light: 'rgba(218, 46, 47, 0.3)',
      main: '#bc0001'
    },
    warning: {
      light: 'rgba(242, 201, 76, 0.3)',
      main: '#c89600'
    },
    info: {
      light: '#1fb2ea',
      main: '#0784b4'
    },
    success: {
      light: '#E4F2EA',
      main: '#0A7839'
    },
    text: {
      secondary: brand.colors.secondary,
      info: brand.colors.infoText
    },
    backgrounds: {
      default: 'red',
      light: brand.colors.lightBackground,
      hover: brand.colors.highlightBackground,
      info: brand.colors.infoBackground,
      infoDark: brand.colors.infoBackgroundDark,
      success: brand.colors.successBackground,
      warning: brand.colors.warningBackground,
      checkbox: brand.colors.checkboxBackground,
      ringing: brand.colors.pictonBlue
    }
  },
  typography: brand.typography,
  shape: {
    borderRadius: 4,
    borderRadiusLarge: 10
  }
});

theme.shadows = [
  'none',
  '0px 0px 14px rgba(0, 0, 0, 0.11)',
  '0px 0px 14px rgba(0, 0, 0, 0.3)',
  ...theme.shadows
];

/* eslint-disable no-param-reassign  */
theme.mediaQueries = {
  ...Object.keys(theme.breakpoints.values).reduce((acc, label) => {
    acc[label] = `(min-width: ${theme.breakpoints.values[label]}px)`;
    acc[`${label}Max`] = `(max-width: ${theme.breakpoints.values[label]}px)`;
    return acc;
  }, {})
};
/* eslint-enable */

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      a: { color: theme.palette.text.secondary },
      'a:hover': { textDecoration: 'none' },
      '*::-webkit-scrollbar': {
        width: '1em'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.15)',
        borderRadius: '10px'
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0,0,0,0.05)',
        boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.25) inset'
      },
      body: {
        backgroundColor: brand.colors.lightBackground
      },
      '.MuiAutocomplete-listbox .MuiCheckbox-root': {
        padding: 0,
        marginRight: theme.spacing(1)
      },
      '.mui-fixed': {
        paddingRight: '0 !important'
      }
    }
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.grey['600']
    },
    colorSecondary: {
      color: theme.palette.grey['600'],
      '&$checked': {
        color: theme.palette.info.light
      },
      '&$disabled': {
        opacity: 0.5
      }
    }
  },
  MuiAlert: {
    standardInfo: {
      border: `solid 1px ${theme.palette.info.main}`,
      backgroundColor: `rgba(31, 178, 234, 0.1)`
    }
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
      fontWeight: 'bold'
    },
    contained: {
      '&.Mui-disabled': {
        color: theme.palette.common.white
      }
    },
    containedSizeLarge: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      fontSize: theme.typography.fontSize
    },
    outlined: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
      borderColor: theme.palette.grey[900],
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey['200'],
        borderColor: 'rgba(0, 0, 0, 0.26)'
      }
    },
    outlinedSizeLarge: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      fontSize: theme.typography.fontSize
    },
    startIcon: {
      height: '1em',
      display: 'flex',
      alignItems: 'center'
    }
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.grey['200']
    }
  },
  MuiFormLabel: {
    root: {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1)
    }
  },
  MuiChip: {
    root: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey['900'],
      height: 28
    }
  },
  MuiToolbar: {
    gutters: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    }
  },
  MuiToggleButton: {
    sizeLarge: { fontSize: theme.typography.fontSize }
  },
  MuiMenuItem: {
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  MuiListItemText: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  MuiInputBase: {
    root: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey['200']
      }
    }
  }
};

export default theme;
