export const CUSTOM_TEMPLATE = 'CUSTOM_TEMPLATE';
export const STANDARD_TEMPLATE = 'STANDARD_TEMPLATE';
export const timeframes = [
  'in the next few days',
  'in the next 10 minutes',
  'in an hour',
  'this afternoon',
  'tomorrow morning',
  'this week sometime',
  'this weekend'
];
