import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import React from 'react';

import Grid from 'components/Grid';
import { StyledButton as SMSButton } from 'components/SMSButton/SMSButton.styles';

export const StyledFilterWrapper = styled((props) => <Grid {...props} />)`
  ${({ theme: { spacing, mediaQueries } }) =>
    css`
      margin-bottom: ${spacing(1)}px;

      @media ${mediaQueries.md} {
        margin-bottom: 0;
      }
    `};
`;

StyledFilterWrapper.displayName = 'StyledFilterWrapper';

export const StyledButtonWrapper = styled.div`
  ${({ theme: { mediaQueries } }) =>
    css`
      ${SMSButton} {
        height: 41px;
        min-width: 0;
      }

      @media ${mediaQueries.lg} {
        .MuiGrid-root {
          .MuiGrid-root {
            width: 100%;
          }
        }
      }
    `};
`;

StyledButtonWrapper.displayName = 'StyledButtonWrapper';

export const StyledButton = styled((props) => <Button {...props} />)`
  ${({ theme: { mediaQueries, spacing } }) =>
    css`
      min-width: 0;
      padding: ${spacing(1.75)}px ${spacing(1.5)}px;

      @media ${mediaQueries.md} {
        padding: ${spacing(2)}px ${spacing(3)}px;
      }

      .MuiButton-startIcon {
        margin: 0;

        + span {
          display: none;
          @media ${mediaQueries.lg} {
            display: flex;
          }
        }
      }

      + ${StyledButton} {
        margin-left: ${spacing(1)}px;
      }
    `};
`;

StyledButton.displayName = 'StyledButton';

// eslint-disable-next-line
export const StyledFilterGrid = styled(({ sticky, drawer, ...restProps }) => (
  <Grid {...restProps} />
))`
  ${({ theme: { mediaQueries, spacing }, sticky, drawer }) =>
    css`
      flex-wrap: wrap;
      justify-content: ${sticky ? 'space-between' : 'flex-start'};
      margin-bottom: ${drawer ? '0' : `${spacing(1)}px`};

      @media ${mediaQueries.mdMax} {
        ${sticky && `margin: 0; margin-bottom: ${spacing(1.5)}px; width: 100%;`};
      }

      @media ${mediaQueries.md} {
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding-top: ${!sticky ? `${spacing(2)}px` : 0};
        align-items: flex-end;
      }

      ${StyledButtonWrapper}:last-child {
        margin-left: auto;
      }

      .sortby-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .MuiToggleButton-root {
          min-width: 0;
          padding: ${spacing(1.75)}px ${spacing(1.4)}px;

          @media ${mediaQueries.md} {
            padding: ${spacing(2)}px ${spacing(3)}px;
          }
        }

        > label {
          margin: 0 ${spacing(1)}px 0 0;
        }

        @media ${mediaQueries.sm} {
          > label {
            margin: 0 ${spacing(1)}px 0 ${spacing(2)}px;
          }
        }
      }

      [data-ref='marked-for-sms'] {
        padding: ${spacing(1.75)}px ${spacing(1.4)}px;

        @media ${mediaQueries.md} {
          padding: ${spacing(2)}px ${spacing(3)}px;
        }
      }
    `};
`;

StyledFilterGrid.displayName = 'StyledFilterGrid';

export const StyledTabletActions = styled.div`
  ${({ theme: { mediaQueries } }) =>
    css`
      display: flex;
      flex: 1 0 280px;

      @media ${mediaQueries.smMax} {
        justify-content: space-between;
      }
    `};
`;

StyledTabletActions.displayName = 'StyledTabletActions';
