const accept = 'accept';
const cancel = 'cancel';
const connect = 'connect';
const destroyed = 'destroyed';
const disabled = 'disabled';
const disconnect = 'disconnect';
const error = 'error';
const incoming = 'incoming';
const inputVolume = 'inputVolume';
const offline = 'offline';
const ready = 'ready';
const registering = 'registering';
const reject = 'reject';
const ringing = 'ringing';
const unregistered = 'unregistered';
const volume = 'volume';

export {
  accept,
  cancel,
  connect,
  destroyed,
  disabled,
  disconnect,
  error,
  incoming,
  inputVolume,
  offline,
  ready,
  registering,
  reject,
  ringing,
  unregistered,
  volume
};
