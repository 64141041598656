const NO_CONNECTION = 'NO_CONNECTION';
const NO_ACCESS = 'NO_ACCESS';
const GENERIC_ERROR = 'GENERIC_ERROR';
const FILTER = 'FILTER';
const SUCCESS = 'SUCCESS';
const SEARCH = 'SEARCH';
const PHONE_SETTINGS = 'PHONE_SETTINGS';
const PHONE_DISABLED = 'PHONE_DISABLED';
const SMS = 'SMS';
const ADD = 'ADD';
const CHECK = 'CHECK';
const NO_DATA = 'NO_DATA';
const NO_SEARCH_RESULTS = 'NO_SEARCH_RESULTS';
const ERROR_EXCLAMATION = 'ERROR_EXCLAMATION';
const EXPAND_LESS = 'EXPAND_LESS';
const EXPAND_MORE = 'EXPAND_MORE';
const TIME_OFF = 'TIME_OFF';
const ALARM = 'ALARM';
const SMS_FAILED = 'SMS_FAILED';
const CONTINUING_STUDENT = 'CONTINUING_STUDENT';
const MICROPHONE_ON = 'MICROPHONE_ON';
const MICROPHONE_OFF = 'MICROPHONE_OFF';
const ADD_NOTE = 'ADD_NOTE';
const STICKY_NOTE = 'STICKY_NOTE';
const PHONE = 'PHONE';
const CONTACTS = 'CONTACTS';
const CALENDAR = 'CALENDAR';
const REFRESH = 'REFRESH';
const COPY_PHONE_NUMBER = 'COPY_PHONE_NUMBER';
const ACCESSIBILITY_NEW = 'ACCESSIBILITY_NEW';
const CHECK_CIRCLE_OUTLINE = 'CHECK_CIRCLE_OUTLINE';
const CONTACT_MAIL = 'CONTACT_MAIL';
const DIRECTIONS_WALK = 'DIRECTIONS_WALK';
const EMAIL = 'EMAIL';
const FINGERPRINT = 'FINGERPRINT';
const GROUP = 'GROUP';
const LANGUAGE = 'LANGUAGE';
const LIBRARY_BOOKS = 'LIBRARY_BOOKS';
const LOCAL_LIBRARY = 'LOCAL_LIBRARY';
const LOCATION_ON = 'LOCATION_ON';
const MENU_BOOK = 'MENU_BOOK';
const NEW_RELEASES = 'NEW_RELEASES';
const REPEAT = 'REPEAT';
const STAR = 'STAR';
const POSTS = 'POSTS';
const TREND_HIGHER = 'TREND_HIGHER';
const TREND_LOWER = 'TREND_LOWER';
const TREND_STEADY = 'TREND_STEADY';

const ICON_TYPES = {
  NO_CONNECTION,
  NO_ACCESS,
  GENERIC_ERROR,
  FILTER,
  SUCCESS,
  SEARCH,
  PHONE_SETTINGS,
  PHONE_DISABLED,
  SMS,
  ADD,
  CHECK,
  NO_DATA,
  NO_SEARCH_RESULTS,
  ERROR_EXCLAMATION,
  EXPAND_LESS,
  EXPAND_MORE,
  TIME_OFF,
  ALARM,
  SMS_FAILED,
  CONTINUING_STUDENT,
  MICROPHONE_ON,
  MICROPHONE_OFF,
  ADD_NOTE,
  STICKY_NOTE,
  PHONE,
  CONTACTS,
  CALENDAR,
  REFRESH,
  COPY_PHONE_NUMBER,
  ACCESSIBILITY_NEW,
  CHECK_CIRCLE_OUTLINE,
  CONTACT_MAIL,
  DIRECTIONS_WALK,
  EMAIL,
  FINGERPRINT,
  GROUP,
  LANGUAGE,
  LIBRARY_BOOKS,
  LOCAL_LIBRARY,
  LOCATION_ON,
  MENU_BOOK,
  NEW_RELEASES,
  REPEAT,
  STAR,
  POSTS,
  TREND_HIGHER,
  TREND_LOWER,
  TREND_STEADY
};

export { ICON_TYPES };
