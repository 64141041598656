import React from 'react';
import PropTypes from 'prop-types';

import { StyledChip } from './Chip.styles';

const Chip = ({ label, onDelete, onClick, ...otherProps }) => {
  if (!label) {
    return null;
  }

  return <StyledChip label={label} onDelete={onDelete} onClick={onClick} {...otherProps} />;
};

Chip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func,
  onClick: PropTypes.func
};

export default Chip;
