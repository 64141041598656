import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrendSteadyIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 12 5'}>
      <rect width="12" height="5" />
    </SvgIcon>
  );
};

export { TrendSteadyIcon };
