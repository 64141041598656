import lodashGet from 'lodash.get';

import { CALL_SCHEDULED, SMS_FAILED, SMS_REQUESTED } from 'app/constants/callStatuses';
import { apiEndpoints } from 'app/constants/apiEndpoints';
import {
  getActiveElaFilter,
  transformStudentData,
  getEngagementIdsByStatus
} from 'utils/transformApiResponseUtils';

const createStudentsStore = (set, get) => ({
  studentsByPeriod: {},
  isFetchingStudents: false,
  forceStudentsRefetch: false,

  fetchStudents: async ({ refetch = false }) => {
    const { isFetchingStudents } = get().studentsStore;
    if (isFetchingStudents) {
      return;
    }

    set((state) => {
      state.studentsStore.isFetchingStudents = true;
    });
    const { periodId } = get().teachingPeriodsStore.selectedTeachingPeriod;

    const { initialiseSearch } = get().searchStore;
    const { courseId } = get().teachingPeriodsStore.teachingPeriods;
    const studentsByPeriod = get().studentsStore.studentsByPeriod[periodId];

    const forceFetch = refetch || get().studentsStore.forceStudentsRefetch;

    // Handle if this period has already been fetched
    if (Boolean(studentsByPeriod) && !forceFetch) {
      const { activeFilters } = get().searchStore;
      const { getUserInfo } = get().authStore;
      const { elaEmail } = getUserInfo();

      const studentData = studentsByPeriod.data;
      const searchData = {
        activeFilters: [...activeFilters, ...getActiveElaFilter(studentData, elaEmail)],
        ...studentsByPeriod
      };

      initialiseSearch(searchData);

      set((state) => {
        state.studentsStore.isFetchingStudents = false;
        state.smsStore.smsFailed = getEngagementIdsByStatus(studentData, SMS_FAILED);
        state.smsStore.smsPending = getEngagementIdsByStatus(studentData, SMS_REQUESTED);
        state.smsStore.smsSent = getEngagementIdsByStatus(studentData, CALL_SCHEDULED);
      });

      return;
    }

    const { apiRequest, handleApiError } = get().actions;
    const { resetActiveFilters } = get().searchStore;

    resetActiveFilters();

    const response = await apiRequest(apiEndpoints.ratData({ courseId, periodId, forceFetch }));

    if (response.status !== 200) {
      handleApiError(response.status);
      set((state) => {
        state.studentsStore.isFetchingStudents = false;
      });
      return;
    }

    const { models = [], pagination = {} } = response.data;
    const { page, pageCount, pageSize } = pagination;
    const morePagesAvailable = pageCount && pageCount > 1;

    const studentList = [...models];

    // Handling remaining pages
    if (morePagesAvailable) {
      const promisedPages = [];

      for (let currentPage = page + 1; currentPage <= pageCount; currentPage += 1) {
        const request = apiRequest(
          apiEndpoints.ratData({
            courseId,
            periodId,
            page: currentPage,
            pageSize,
            forceFetch
          })
        );
        promisedPages.push(request);
      }

      await Promise.all(promisedPages).then((pages) => {
        pages.forEach((pageResponse) => {
          if (pageResponse.status !== 200) {
            handleApiError(pageResponse.status);
            set((state) => {
              state.studentsStore.isFetchingStudents = false;
            });
          }

          studentList.push(...pageResponse.data.models);
        });
      });
    }

    // Handling filtering students by sms status
    const { userLoginId } = get().authStore.userSession;
    const { smsSent, smsFailed, smsPending, ...newStudentsByPeriod } = transformStudentData(
      studentList,
      userLoginId
    );

    initialiseSearch(newStudentsByPeriod);

    set((state) => {
      state.studentsStore.isFetchingStudents = false;
      state.studentsStore.forceStudentsRefetch = false;
      state.studentsStore.studentsByPeriod[periodId] = newStudentsByPeriod;

      state.smsStore.smsSent = smsSent;
      state.smsStore.smsFailed = smsFailed;
      state.smsStore.smsPending = smsPending;
    });
  },

  getStudentsDataByActivePeriod() {
    const { selectedTeachingPeriod } = get().teachingPeriodsStore;

    if (!selectedTeachingPeriod) {
      return [];
    }

    const students = get().studentsStore.studentsByPeriod[selectedTeachingPeriod.periodId];
    return lodashGet(students, 'data') || [];
  }
});

export { createStudentsStore };
