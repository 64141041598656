import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StickyNoteIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 17 18'}>
      <path d="M14.25 3.75V10.5H10.5V14.25H3.75V3.75H14.25ZM14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H11.25L15.75 11.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 10.5H5.25V9H9V10.5ZM12.75 7.5H5.25V6H12.75V7.5Z" />
    </SvgIcon>
  );
};

export { StickyNoteIcon };
