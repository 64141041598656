import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NoSearchIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 21 18'}>
      <path d="M15.4999 11H14.7099L14.4299 10.73C15.4099 9.59 15.9999 8.11 15.9999 6.5C15.9999 2.91 13.0899 0 9.49991 0C6.07991 0 3.27991 2.64 3.02991 6H5.04991C5.29991 3.75 7.17991 2 9.49991 2C11.9899 2 13.9999 4.01 13.9999 6.5C13.9999 8.99 11.9899 11 9.49991 11C9.32991 11 9.16991 10.97 8.99991 10.95V12.97C9.16991 12.99 9.32991 13 9.49991 13C11.1099 13 12.5899 12.41 13.7299 11.43L13.9999 11.71V12.5L18.9999 17.49L20.4899 16L15.4999 11Z" />
      <path d="M6.46995 7.82031L3.99995 10.2903L1.52995 7.82031L0.819946 8.53031L3.28995 11.0003L0.819946 13.4703L1.52995 14.1803L3.99995 11.7103L6.46995 14.1803L7.17995 13.4703L4.70995 11.0003L7.17995 8.53031L6.46995 7.82031Z" />
    </SvgIcon>
  );
};

export { NoSearchIcon };
