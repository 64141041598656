import React from 'react';
import { render as ReactDOMRender } from 'react-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import App from './app';

const render = (AppComponent) => {
  return ReactDOMRender(
    <ErrorBoundary>
      <AppComponent />
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./app', () => {
    // eslint-disable-next-line
    const NextApp = require('./app').default;
    render(NextApp);
  });
}
