import axios from 'axios';

import { getApiKey, getApiRoot } from 'utils/environmentUtils';
import { errorMessages } from '../app/constants/errorMessages';

const constructApiTemplate = (action) => {
  const template = {
    type: 'API_ACTION',
    dispatchTypes: [],
    url: null,
    method: 'get',
    data: null,
    headers: {
      'Content-Type': 'application/json'
    },
    params: {}
  };

  return { ...template, ...action };
};

export const constructErrorPayload = (error) => {
  if (error.status) {
    return error;
  }

  if (error.response) {
    return {
      status: error.response.status,
      message: error.response.statusText,
      logs: error.response.data
    };
  }

  if (error.request) {
    return {
      status: 500,
      message: error.message,
      logs: error.request
    };
  }

  return {
    status: 500,
    message: error.message
  };
};

const handleApiRequest = async (request) => {
  try {
    const response = await axios(request);
    return {
      status: 200,
      data: response.data
    };
  } catch (error) {
    return constructErrorPayload(error);
  }
};

export const apiCall = async ({ request, accessToken }) => {
  const { params, data, method, url } = request;

  const headers = { ...request.headers };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
    headers['X-Api-Key'] = getApiKey();
  }

  const originalRequest = {
    url: getApiRoot() + url,
    method,
    ...(headers && { headers }),
    ...(params && { params }),
    ...(data && { data })
  };

  try {
    const response = await axios(originalRequest);
    return response.data;
  } catch (error) {
    throw constructErrorPayload(error);
  }
};

const getErrorMessage = (error) => {
  switch (error.status) {
    case 401:
      return errorMessages.unauthorisedAccess;

    case 404:
      return error.notFoundMessage ?? errorMessages.genericNotFound;

    default:
      return errorMessages.genericError;
  }
};

export { handleApiRequest, constructApiTemplate, getErrorMessage };
