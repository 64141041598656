import styled, { css } from 'styled-components/macro';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export const StyledAutocomplete = styled((props) => <Autocomplete {...props} />)`
  ${({ theme: { spacing, palette } }) =>
    css`
      &.has-value {
        .MuiOutlinedInput-notchedOutline {
          border: solid 2px ${palette.text.primary};
        }
      }

      .MuiFormControl-root {
        width: 100%;

        legend {
          display: none;
        }

        .MuiFormLabel-root {
          color: ${palette.text.primary};
          position: relative;
          transform: none;
          margin-bottom: ${spacing(1)}px;
        }

        .MuiOutlinedInput-notchedOutline {
          top: 0;
        }

        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
          padding: ${spacing(0.5)}px ${spacing(1)}px;
          background: ${palette.common.white};

          ::placeholder {
            opacity: 1;
            display: block;
          }
        }

        label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input {
          ::placeholder {
            opacity: 1 !important;
          }
        }

        .MuiAutocomplete-tag {
          margin-top: 2px;
        }

        .MuiAutocomplete-tag {
          height: 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }
        .MuiChip-root {
          display: none;
          max-width: calc(100% - 70px);

          .MuiChip-label {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .MuiAutocomplete-tag,
        .MuiChip-root {
          + .MuiInputBase-input {
            display: none;
          }
        }
      }
    `};
`;

StyledAutocomplete.displayName = 'StyledAutocomplete';
