import {
  palette,
  position,
  sizing,
  spacing as materialSpacing,
  typography
} from '@material-ui/system';
import styled, { css } from 'styled-components/macro';
import Container from '@material-ui/core/Container';
import React from 'react';

// eslint-disable-next-line no-unused-vars
export default styled(({ minHeight, ...rest }) => <Container {...rest} />)`
  ${({ theme: { mediaQueries, spacing } }) =>
    css`
      padding-left: ${spacing(1.5)}px;
      padding-right: ${spacing(1.5)}px;

      @media ${mediaQueries.lg} {
        padding-left: ${spacing(3)}px;
        padding-right: ${spacing(3)}px;
      }

      &.MuiContainer-disableGutters {
        padding-left: 0;
        padding-right: 0;
      }
    `}

  ${sizing}
  ${typography}
  ${materialSpacing}
  ${palette}
  ${position}
`;
