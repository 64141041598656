import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Header from 'components/Header';
import { StyledPageLayout } from './PageLayout.styles';

const PageLayout = ({ children, background }) => {
  return (
    <StyledPageLayout background={background}>
      <Header />
      {children && <Box>{children}</Box>}
    </StyledPageLayout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  background: PropTypes.oneOf(['light', 'none'])
};

export default PageLayout;
