import React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import StickyContainer from 'components/StickyContainer';
import { useStore } from 'store';

const withStickyContainer = (WrappedComponent, threshold = 200) => {
  const StickyWrappedComponent = (props) => {
    // For some reason using useStore here to get this value breaks all tests that consume
    // withStickyContainer in their tree. Have not found a working solution for this yet
    const isDrawerOpen = useStore((state) => Boolean(state.drawerStore.activeDrawerType));

    const isSticky =
      useScrollTrigger({
        disableHysteresis: true,
        threshold
      }) || false;

    return (
      <StickyContainer isSticky={!isDrawerOpen && isSticky}>
        <WrappedComponent {...props} isSticky={isSticky} />
      </StickyContainer>
    );
  };

  StickyWrappedComponent.displayName = 'StickyWrappedComponent';

  return StickyWrappedComponent;
};

export default withStickyContainer;
