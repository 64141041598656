import sortingOptions from 'app/constants/sortingOptions';
import { createSearchIndex, getSearchResults, orderResults } from 'utils/searchUtils';

const searchState = {
  isSearching: false,
  searchTerm: '',
  activeFilters: [],
  searchResults: [],
  filterOptions: {
    learningAdvisors: [],
    learningGroups: [],
    riskLevels: [],
    riskReasons: [],
    otherDetails: [],
    callStatuses: []
  },
  sortBy: sortingOptions[0],
  sortingOptions,
  searchIndex: null,
  isExpandAll: false,
  pageSize: 60,
  currentPage: 1
};

const createSearchStore = (set, get) => ({
  ...searchState,

  setIsSearching(value) {
    set((state) => {
      state.searchStore.isSearching = value;
    });
  },

  resetActiveFilters() {
    set((state) => {
      state.searchStore.currentPage = 1;
      state.searchStore.activeFilters = [];
    });
  },

  clearFilters() {
    const { getSearchData, resetActiveFilters } = get().searchStore;
    const searchData = getSearchData();
    const searchResults = getSearchResults({ ...searchData, filters: [] });

    resetActiveFilters();
    set((state) => {
      state.searchStore.searchResults = searchResults;
      state.searchStore.isSearching = false;
    });
  },

  toggleExpandAll() {
    const { isExpandAll } = get().searchStore;
    set((state) => {
      state.searchStore.isExpandAll = !isExpandAll;
    });
  },

  // TBH, not a huge fan of this but as we're going to get rid of this code for
  // algolia I won't bother updating it
  updateFilter(filterKey, values) {
    const { activeFilters, getSearchData } = get().searchStore;

    // Clear filters in the store for the current filterKey
    const newFilterSet = activeFilters.filter((filters) => Object.keys(filters)[0] !== filterKey);

    // Add all the new values for the current filterKey
    values.forEach((value) => newFilterSet.push({ [filterKey]: value }));

    set((state) => {
      state.searchStore.currentPage = 1;
      state.searchStore.activeFilters = newFilterSet;
    });

    // Set searchResults based on new filters
    const searchData = getSearchData();
    const searchResults = getSearchResults({ ...searchData, filters: newFilterSet });

    set((state) => {
      state.searchStore.searchResults = searchResults;
      state.searchStore.isSearching = false;
    });
  },

  removeFilter(filterKey, value) {
    const { activeFilters, getSearchData } = get().searchStore;

    // Remove filter based on the key and value passed
    const newFilterSet = activeFilters.filter((filterObj) => filterObj[filterKey] !== value);

    set((state) => {
      state.searchStore.currentPage = 1;
      state.searchStore.activeFilters = newFilterSet;
    });

    // Set searchResults based on new filters
    const searchData = getSearchData();
    const searchResults = getSearchResults({ ...searchData, filters: newFilterSet });

    set((state) => {
      state.searchStore.searchResults = searchResults;
      state.searchStore.isSearching = false;
    });
  },

  searchForStudent(searchTerm) {
    set((state) => {
      state.searchStore = {
        ...get().searchStore,
        currentPage: 1,
        searchTerm,
        isSearching: true
      };
    });

    const { getSearchData } = get().searchStore;
    const searchData = getSearchData();
    const searchResults = getSearchResults({ ...searchData, searchTerm });

    set((state) => {
      state.searchStore.searchResults = searchResults;
      state.searchStore.isSearching = false;
    });
  },

  setSortingOption(sortBy) {
    const { searchResults } = get().searchStore;

    set((state) => {
      state.searchStore = {
        ...get().searchStore,
        sortBy,
        searchResults: orderResults(searchResults, sortBy),
        currentPage: 1
      };
    });
  },

  initialiseSearch({ data, filterOptions, activeFilters }) {
    const res = get();

    const searchData = res.searchStore.getSearchData();

    const newSearchIndex = createSearchIndex(data);
    const searchResults = getSearchResults({
      ...searchData,
      searchIndex: newSearchIndex,
      filters: activeFilters,
      data
    });

    set((state) => {
      state.searchStore = {
        ...get().searchStore,
        currentPage: 1,
        filterOptions,
        isSearching: false,
        searchIndex: newSearchIndex,
        searchResults,
        activeFilters: activeFilters || [],
        searchTerm: ''
      };
    });
  },

  clearSearchTerm() {
    const { searchForStudent } = get().searchStore;
    searchForStudent('');
  },

  clearSearchTermAndFilters() {
    const { clearFilters } = get().searchStore;

    clearFilters();
    set((state) => {
      state.searchStore.currentPage = 1;
      state.searchStore.searchTerm = '';
    });
  },

  setCurrentPage(currentPage) {
    set((state) => {
      state.searchStore.currentPage = currentPage;
    });
  },

  getSearchData() {
    const { searchIndex, searchTerm, activeFilters, sortBy } = get().searchStore;
    const students = get().studentsStore.getStudentsDataByActivePeriod();

    return {
      data: students,
      searchIndex,
      searchTerm,
      activeFilters,
      sortBy
    };
  }
});

export { createSearchStore };
