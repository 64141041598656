import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FollowTheSignsIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 18 19'}>
      <path d="M6.91675 3.58333C7.83342 3.58333 8.58342 2.83333 8.58342 1.91667C8.58342 1 7.83342 0.25 6.91675 0.25C6.00008 0.25 5.25008 1 5.25008 1.91667C5.25008 2.83333 6.00008 3.58333 6.91675 3.58333ZM3.79175 6.41667L1.50008 18.1667H3.25008L4.70842 11.5L6.50008 13.1667V18.1667H8.16675V11.875L6.45842 10.1667L6.95842 7.66667C8.04175 9 9.66675 9.83333 11.5001 9.83333V8.16667C9.95842 8.16667 8.62508 7.33333 7.87508 6.125L7.08342 4.79167C6.79175 4.29167 6.25008 4 5.66675 4C5.45842 4 5.25008 4.04167 5.04175 4.125L0.666748 5.91667V9.83333H2.33342V7.04167L3.79175 6.41667ZM9.83342 0.666667V6.5H12.9584V18.1667H14.2084V6.5H17.3334V0.666667H9.83342ZM14.0084 5.66667V4.20833H11.0834V2.95833H14.0084V1.5L16.0834 3.58333L14.0084 5.66667Z" />
    </SvgIcon>
  );
};

export { FollowTheSignsIcon };
