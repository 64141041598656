import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';

import { StyledAppBar } from './StickyContainer.styles';

const StickyContainer = ({ children, isSticky }) => {
  const appBarRef = useRef();

  return (
    <>
      <div ref={appBarRef}>
        <StyledAppBar color="default" sticky={isSticky.toString()} elevation={isSticky ? 2 : 0}>
          <Toolbar disableGutters>{children}</Toolbar>
        </StyledAppBar>
      </div>
      {isSticky && (
        <Toolbar style={{ height: appBarRef.current && appBarRef.current.offsetHeight }} />
      )}
    </>
  );
};

StickyContainer.propTypes = {
  children: PropTypes.node,
  isSticky: PropTypes.bool
};

StickyContainer.defaultProps = {
  threshold: 200,
  isSticky: false
};

StickyContainer.displayName = 'StickyContainer';

export default StickyContainer;
