export default {
  colors: {
    primary: '#001738',
    secondary: '#f68a1e',
    lightBackground: '#f7f7f7 ',
    highlightBackground: '#ECF4F6',
    infoBackground: '#E8F7FD',
    infoBackgroundDark: '#B5E1F2',
    successBackground: '#B6D9C5',
    infoText: '#0088BC',
    warningBackground: '#FDF9E9',
    checkboxBackground: '#BCE8F9',
    pictonBlue: '#57C5EF'
  },
  appBar: {
    useColor: 'primary'
  },
  logo: {
    height: 7,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0'
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 12,
    body1: {
      fontSize: 12
    },
    body2: {
      fontSize: 16
    },
    h3: {
      fontSize: 24,
      fontWeight: 700
    },
    h4: {
      fontSize: 18,
      fontWeight: 700
    }
  }
};
