import styled, { css } from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import React from 'react';
import { lighten } from 'polished';

import Grid from 'components/Grid';

export const StyledGrid = styled((props) => <Grid {...props} />)`
  ${({ theme: { mediaQueries, spacing } }) =>
    css`
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .MuiGrid-root {
        text-align: center;

        @media ${mediaQueries.lg} {
          :last-child {
            padding-left: ${spacing(1)}px;
          }
        }
      }
    `};
`;

StyledGrid.displayName = 'StyledGrid';

export const StyledButton = styled(Button)`
  ${({ theme: { mediaQueries } }) =>
    css`
      text-transform: none;
      width: 100%;
      line-height: 1.1;

      @media ${mediaQueries.lg} {
        min-width: 190px;
      }

      a {
        color: currentColor;
      }
    `};
`;

StyledButton.displayName = 'StyledButton';

export const StyledMarkedCounter = styled.div`
  ${({ theme: { spacing, palette, shape, mediaQueries }, status }) =>
    css`
      font-weight: bold;
      line-height: 1.1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${spacing(2)}px ${spacing(1)}px;
      border-radius: ${shape.borderRadius * 2}px;
      justify-content: center;

      ${status === 1 &&
      `background: ${lighten(0.3, palette.error.light)}; color: ${palette.error.main};`};
      ${status === 2 && `background: ${palette.grey['100']}; color: ${palette.grey['900']};`};
      ${status === 3 && `background: ${palette.backgrounds.hover}; color: ${palette.text.info};`};
      ${status === 4 &&
      `background: ${lighten(0.15, palette.backgrounds.success)}; color: ${palette.success.main}`};

      @media ${mediaQueries.sm} {
        padding: 14px ${spacing(1)}px;
      }

      @media ${mediaQueries.md} {
        padding: ${spacing(2)}px;
      }

      .MuiSvgIcon-root {
        margin-right: ${spacing(1)}px;
        margin-bottom: ${status === 3 ? '-3px' : '0'};
        fill: ${status === 3 ? palette.text.info : 'currentColor'};
      }

      .MuiButtonBase-root {
        color: ${palette.text.info};
        padding-right: 0;
        padding-left: 0;
        margin-left: ${spacing(1)}px;
        min-width: 0;
        line-height: 1;
      }
    `}
`;

StyledMarkedCounter.displayName = 'StyledMarkedCounter';
