import styled, { css } from 'styled-components/macro';
import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { brand } from 'brand';

// eslint-disable-next-line
export const StyledAppBar = styled(({ drawerOpen, ...props }) => (
  <AppBar position="relative" color={brand.appBar.useColor} elevation={1} {...props} />
))`
  ${({ theme: { zIndex }, drawerOpen }) =>
    css`
      z-index: ${drawerOpen ? zIndex.drawer - 1 : zIndex.modal + 1};
    `}
`;

export const StyledHeaderLogo = styled.img`
  ${({ theme: { spacing } }) =>
    css`
      height: ${spacing(brand.logo.height)}px;
      margin: ${brand.logo.margin.top} ${brand.logo.margin.right} ${brand.logo.margin.bottom}
        ${brand.logo.margin.left};
    `}
`;

StyledHeaderLogo.displayName = 'StyledHeaderLogo';

export const StyledHeaderTitle = styled((props) => <Typography variant={'body2'} {...props} />)`
  font-weight: bold;
`;

StyledHeaderTitle.displayName = 'StyledHeaderTitle';
