import { connectRefinementList, connectSearchBox } from 'react-instantsearch-dom';
import isEmpty from 'lodash.isempty';
import React from 'react';

import Drawers from '../../app/constants/drawers';
import { useStore } from '../../store';

const VirtualRefinementList = connectRefinementList(() => null);
const VirtualSearchBox = connectSearchBox(() => null);

const VirtualSearchWidgets = () => {
  const { queryState, refinementState } = useStore((state) => state.algoliaStore);
  const activeDrawerType = useStore((state) => state.drawerStore.activeDrawerType);

  return (
    <>
      {!isEmpty(refinementState) &&
        activeDrawerType !== Drawers.FILTERS &&
        refinementState.map(({ attribute, list }) => (
          <VirtualRefinementList key={attribute} defaultRefinement={list} attribute={attribute} />
        ))}
      {activeDrawerType !== Drawers.SEARCH && <VirtualSearchBox currentRefinement={queryState} />}
    </>
  );
};

export default VirtualSearchWidgets;
