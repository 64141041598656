import styled, { css } from 'styled-components/macro';

export const StyledPageLayout = styled.div`
  ${({ theme: { palette, mediaQueries }, background }) =>
    css`
      background-color: ${palette.common.white};

      ${background === 'light' && `background-color: ${palette.backgrounds.light};`};
      ${background === 'none' && `background-color: transparent`};

      @media ${mediaQueries.md} {
        background-color: ${palette.common.white};
        ${background === 'none' && `background-color: transparent`};
      }
    `}
`;

StyledPageLayout.displayName = 'PageLayout';
