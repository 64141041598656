import styled, { css } from 'styled-components/macro';
import AppBar from '@material-ui/core/AppBar';
import React from 'react';

export const StyledAppBar = styled((props) => <AppBar {...props} />)`
  ${({ theme: { palette, spacing }, sticky }) =>
    css`
      position: ${sticky === 'true' ? 'fixed' : 'static'};
      background-color: ${sticky === 'true' ? palette.common.white : 'transparent'};
      padding-right: 0 !important;

      .MuiToolbar-regular {
        min-height: 0;
        padding: ${sticky === 'true' ? `${spacing(1.5)}px 0 0` : 0};
      }
    `};
`;

StyledAppBar.displayName = 'StyledAppBar';
