import styled, { css } from 'styled-components/macro';
import Drawer from '@material-ui/core/Drawer';
import React from 'react';

export const StyledDrawer = styled((props) => <Drawer classes={{ paper: 'drawer' }} {...props} />)`
  ${({ theme: { spacing, palette, zIndex } }) => css`
    pointer-events: auto;
    z-index: ${zIndex.modal + 2};

    .MuiDrawer-paper {
      background: ${palette.common.white};
    }

    & .drawer {
      padding: ${spacing(2)}px;
    }
  `};
`;

StyledDrawer.displayName = 'StyledDrawer';
