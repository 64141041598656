import { palette, spacing, typography } from '@material-ui/system';
import styled, { css } from 'styled-components/macro';
import React from 'react';
import Typography from '@material-ui/core/Typography';

export default styled(({ className, ...rest }) => <Typography className={className} {...rest} />)`
  ${({ clamp }) =>
    css`
      ${clamp &&
      css`
        display: -webkit-box;
        -webkit-line-clamp: ${clamp};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `};
    `}
  ${typography}
    ${spacing}
    ${palette}
`;
