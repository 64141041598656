import {
  border,
  display,
  flexbox,
  palette,
  sizing,
  spacing,
  typography
} from '@material-ui/system';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components/macro';

export default styled(Grid)`
  ${display}
  ${typography}
  ${spacing}
  ${palette}
  ${sizing}
  ${flexbox}
  ${border}
`;
