import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledBackdrop } from './LoadingSpinner.styles';

const LoadingSpinner = ({ backdrop = true, className, dark = false }) => {
  if (backdrop) {
    return (
      <StyledBackdrop open={true} data-testid="loading-spinner">
        <CircularProgress color={dark ? 'secondary' : 'inherit'} className={className} />
      </StyledBackdrop>
    );
  }
  return (
    <div style={{ textAlign: 'center' }} data-testid="loading-spinner">
      <CircularProgress color="secondary" className={className} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  backdrop: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool
};

export default LoadingSpinner;
